<template>
  <v-app>
    <v-container fluid full-height>
      <v-form class="imgup" name="imgup" @submit.prevent="save_test">
        <v-row min-height>
          <v-col cols="12" align="center">
            <br />
            <p>
              <b>{{ tdata['header'] }}</b>
            </p>
            <br />
           <p>{{ tdata['description'] }}</p> 
          </v-col>
        </v-row>

        
        <v-row>
          <v-col cols="0"></v-col>
          <v-col cols="12" >
            <v-card elevation="8" class="pa-2" rounded v-html="tdata['data']['question']">            
          </v-card>
          </v-col>
          <v-col cols="0"></v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row v-if="tdata['data']['type'] == 'oneanswer'">
          <v-col cols="1"></v-col>
          <v-col cols="10">
           
            <v-radio-group v-model="answer" column>
              <div v-for="(answer, i) in tdata['data']['answers']" :key="i"
              class="mt-2 mb-2 pt-1 pb-1"
              style="border-radius:5px;"
              :class="answered&&i==tdata['data']['correct']?'correct':answered&&i!=tdata['data']['correct']?'wrong':''"
              >
                
                <v-radio
                :disabled="answered"
                  color="#ee6424"
                  :label="answer"
                  :value="i"
                  name="answer"
                ></v-radio>
                <!-- <br/> -->
              </div>
            </v-radio-group>
            
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

        <v-row v-if="tdata['data']['type'] == 'multiselect'">
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <div v-for="(answer, i) in tdata['data']['answers']" :key="i"
            class="mt-2 mb-2"
            style="border-radius:5px;"
            :class="answered&&tdata['data']['correct'].split(';').includes(i)?'correct':answered&&!tdata['data']['correct'].split(';').includes(i)?'wrong':''"
            >
              <v-checkbox
                :disabled="answered"
                v-model="answers"
                color="#ee6424"
                class="ma-0 pa-0"
                :value="i"
              >
                <template v-slot:label>
                  <div>
                    {{answer}}
                  </div>

                </template>
                <v-divider></v-divider>
              </v-checkbox>
            </div>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>


        <v-row fluid v-if="!answered">
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-btn rounded class="primary_button" type="submit"
              >{{$t('btn_save')}}</v-btn
            >
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
        <v-row fluid v-if="answered">
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-btn rounded class="primary_button"  @click='$router.push({ name: "Tasks"})'
              >{{$t('btn_close')}}</v-btn
            >
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-app>
</template>
<style scoped>
.correct{
  background-color: #3CB371;
}
.wrong{
  background-color: #b22222a3;
}
</style>

<script>
import axios from "axios";
import { API_LOCATION } from "../../config";
export default {
  data() {
    return {
      url: null,
      answer: null,
      answers: [],
      file: "",
      task: false,
      task_id: null,
      descr: "",
      header: "",
      tdata: [],
      answered:false

    };
  },
  mounted() {
    
  },
  methods: {
    
    save_test: function () {

      console.log(this.answer);
      console.log(this.answers.length);
      console.log(this.answer);
      console.log(this.answers);
      if(this.answer==null&&this.answers.length==0)
      {
         this.$emit('pushe', {header:'Повідомлення',text:'<font style="font-size: 19px;">Обери хоча б одну відповідь</font>'});
        return;
      }
      
      axios
        .post(API_LOCATION,
        { data: "component_data", action: "save_test", task: this.task_id, 'answer':this.answer, 'answers':this.answers },
          { headers: { "content-type": "application/x-www-form-urlencoded" } }
        )
        .then((resp) => {
          console.log("SUCCESS!!");
          if (resp.data == "OK") {

            this.answered=true;
            //this.$router.push({ name: "Tasks" });
            // this.$router.push('/')
          }
        });
    },
  },
  computed: {
   
    title() {
        return this.$t('title')
      }
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");

    console.log(this.$route.params.routeparam);
    if (this.$route.params.routeparam) {
      this.task_id = this.$route.params.routeparam;
      this.task = true;
      axios
        .post(
          API_LOCATION,
          { data: "component_data", action: "load_task", id: this.task_id },
          { headers: { "content-type": "application/x-www-form-urlencoded" } }
        )
        .then((resp) => {
          this.tdata = JSON.parse(resp.data.data_json);
          this.descr = this.tdata.description_ru;
          this.header = this.tdata.header_ru;
          const ex = resp.data.id_ex
          const moderate=resp.data.moderate
          const correct=resp.data.correct

          if(ex!=null)
          {
            this.task = false;
            this.$router.push({ name: "Tasks" });
            if(moderate==1)
            {
              this.$emit('pushe', {header:'Повідомлення',text:'Завдання знаходиться на модерації.'});
            }
            else
            {
              
              var task_state=correct==1?'Успішно':'Помилка';
              this.$emit('pushe', {header:'Повідомлення',text:'Завдання вже виконано.<br>Статус: <b>'+task_state+'</b>'});
            }
          }
          //console.log(this.tdata);
        });
    } else {
      this.task = false;
      this.$router.push({ name: "Main" });
    }
  },
};
</script>

<i18n>
{
  
  "ua":{
      "title":"Тест",
      "btn_save":"Зберегти",
      "btn_close":"Закрити"
      

  }
}
</i18n>